.navbar{
    background-color: var(--yellow-theme-nav-background-color );
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    
    &__container{
        display: flex;
        align-items: center;
        width: 100%;
        height: inherit;
        padding:0px 20px ;
        flex: 1;
        

        &__logo{
            color: var(--yellow-theme-main-color);
            cursor: pointer;
        
        }
        &__menu{
            display: flex;
            list-style: none;
            text-align: center;

            &__item{
                list-style: 40px;
                margin-right: 1.5rem;
                font-size: 1.6rem;

                &::after{
                    content: '';
                    display: block;
                    height: 3px;
                    width: 0;
                    background: transparent;
                    transition: all 0.5s ease;
                }
                &:hover::after{
                    width: 100%;
                    background-color: var(--yellow-theme-main-color);
                }
                &__links{
                    color: var(--yellow-theme-main-color);
                    text-decoration: none;
                    padding: 0.5rem;
                }
            }
        }
    }
}

.nav-icon{
    display: none;
}


 @media only screen and (max-width : 768px){
    .navbar__container__menu{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
        

        &.active {
                background-color: #181818;
                left: 0px;
                opacity: 1;
                
                /* Show menu when active */
            }
            &__item{
                border-bottom: 1px solid #282828;
                margin: 0px;
                padding: 20px 0px;
            }
    }

         
    .nav-icon{
        display: block;
        cursor: pointer;
        color: var(--yellow-theme-main-color);
        padding-right: 20px;
    }
}
